export class User {
    constructor (
        public email: string,
        public id: number,
        public token: string,
        public role: string,
        public city: string,
        public countryId: number,
        public countryName: string,
        public districtId: number,
        public firstName: string,
        public lastName: string,
        public latitude: number,
        public longitude: number,
        public mobileNumber: string,
        public mobileNumberCountryId: number,
        public parentId: number,
        public pincode: number,
        public profilePhotoPath: string,
        public stateId: number,
        public status: boolean,
        public street: string
    ) {}
}
