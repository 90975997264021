import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { getSpanishPaginatorIntl } from './app/Spanish-paginator-intl';

import '../src/themes/upl-echart-theme';

providers: [
  { provide: MatPaginatorIntl, useValue: getSpanishPaginatorIntl() }
]

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
