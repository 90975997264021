import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '@app/core/auth/auth.guard';
import {PreLoginGuard} from '@app/routing-guards/pre-login.guard';
import {RoleGuard} from '@app/core/auth/role.guard';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
    },
    {
        path: 'profile',
        loadChildren: () =>
            import('@app/users/profile/profile.module')
                .then(m => m.ProfileModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'login',
        loadChildren: () => import('app/login/login.module').then(m => m.LoginModule),
        canActivate: [PreLoginGuard]
    },
    {
        path: 'sign-in',
        loadChildren: () =>
            import('app/pages/new-sign-in/new-sign-in.module')
                .then(m => m.NewSignInModule),
        canActivate: [PreLoginGuard]
    },
    {
        path: 'sso/sign-out',
        loadChildren: () =>
            import('app/sso-sign-out/sso-sign-out.module')
                .then(m => m.SsoSignOutModule),
        canActivate: [PreLoginGuard]
    },
    {
        path: 'sso/error',
        loadChildren: () =>
            import('app/sso-auth-error/sso-auth-error.module')
                .then(m => m.SsoAuthErrorModule),
        canActivate: [PreLoginGuard]
    },
    {
        path: 'dashboard',
        loadChildren: () =>
            import('@app/admin-dashboard/admin-dashboard.module')
                .then(m => m.AdminDashboardModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'territory-manager-details',
        loadChildren: () =>
            import('@app/user-management/user-management.module')
                .then(m => m.UserManagementModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'distributor-details',
        loadChildren: () =>
            import('@app/distributor-details/distributor-details.module')
                .then(m => m.DistributorDetailsModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'history-details',
        loadChildren: () =>
            import('@app/history-details/history-details.module')
                .then(m => m.HistoryDetailsModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'sign-out',
        loadChildren: () =>
            import('@app/sign-out/sign-out.module').then(m => m.SignOutModule),
        canActivate: [AuthGuard]
    },
    { path: '**',
        redirectTo: 'dashboard' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: false,
            scrollPositionRestoration: 'enabled',
            relativeLinkResolution: 'legacy'
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
