import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommunicationServiceService {
  public filterGraph: Subject<object> = new Subject();
  public changeHeader: Subject<string> = new Subject();
  public patchFiltersToHeader: Subject<object> = new Subject();
  public fetchGridData: Subject<object> = new Subject();
  constructor() { }
}
