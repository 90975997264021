import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
    UrlTree
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {UserStateService} from "@app/core/auth/user-state.service";

@Injectable({ providedIn: 'root' })
export class PreLoginGuard implements CanActivate {
    constructor(private userStateService: UserStateService,
                private router: Router) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        router: RouterStateSnapshot
    ):
        | boolean
        | UrlTree
        | Promise<boolean | UrlTree>
        | Observable<boolean | UrlTree> {
        if (this.userStateService.isLoggedIn()) {
            this.router.navigate(['/']);
            return false;
        } else {
            return true;
        }
    }
}
