import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler, HttpErrorResponse,
} from '@angular/common/http';
import {take, exhaustMap, catchError} from 'rxjs/operators';
import {UserStateService} from "@app/core/auth/user-state.service";
import {throwError} from "rxjs";
import {Router} from "@angular/router";
import {User} from '@app/core/auth/user.model';
import {environment} from '@env/environment';

@Injectable()
export class LocationBasedHeaderService implements HttpInterceptor {

    currentCountryName: string;
    userCurrentData: User;
    defaultLanguage: string = environment.defaultLanguage;

    constructor(private userStateService: UserStateService,
                private router: Router) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        this.userCurrentData = JSON.parse(localStorage.getItem('userData'));
        if (this.defaultLanguage === 'es') {
            this.currentCountryName = localStorage.getItem('currentCountry');
            if ((this.currentCountryName === null || this.currentCountryName === undefined) && this.userCurrentData) {
                if (!(this.userCurrentData.countryName === 'Argentina' || this.userCurrentData.countryName === 'Paraguay')) {
                    this.currentCountryName = 'Argentina';
                } else {
                    this.currentCountryName = this.userCurrentData.countryName;
                }
            } else {
                if (!(this.currentCountryName === 'Argentina' || this.currentCountryName === 'Paraguay')) {
                    this.currentCountryName = 'Argentina';
                }
            }
        } else if (this.defaultLanguage === 'en' && this.userCurrentData && (this.userCurrentData.role === 'SUPER_ADMIN' || this.userCurrentData.role === 'ADMIN' ||
                    this.userCurrentData.role === 'ADMIN_EXECUTIVE')) {
            this.currentCountryName = 'India';
        } else {
            this.currentCountryName = 'All';
        }
        return this.userStateService.user$.pipe(
            take(1),
            exhaustMap(user => {
                const modifiedReq = req.clone({
                    headers: req.headers.set('countryName', `${this.currentCountryName}`),
                });
                return next.handle(modifiedReq).pipe(catchError(this.handleError.bind(this)));
            })
        );
    }

    private handleError(errorRes: HttpErrorResponse) {
        if (errorRes && errorRes.status === 401
            && !this.router.url.includes('edit')
            && !this.router.url.includes('add')
            && !this.router.url.includes('change-password'))  {
            localStorage.clear();
            if (this.router.url !== '/sign-in' &&
                this.router.url !== '/forgot-password') {
                window.location.reload();
            }
        }
        return throwError(errorRes);
    }
}
