import {AppHeaderModule} from "@app/shared/app-header/app-header.module";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {AuthService} from "@app/core/auth/auth.service";
import {AppComponent} from "@app/app.component";
import {EventsService} from "@app/events/events.service";
import {MAT_DIALOG_DEFAULT_OPTIONS} from "@angular/material/dialog";
import {SpinnerModule} from "@app/shared/spinner/spinner.module";
import {ReactiveFormsModule} from "@angular/forms";
import {AppRoutingModule} from "@app/app-routing.module";
import {SharedModule} from "@app/shared";
import {BrowserModule} from "@angular/platform-browser";
import {NgModule} from "@angular/core";
import {AuthGuard} from "@app/core/auth/auth.guard";
import {AuthInterceptorService} from "@app/core/auth/auth-interceptor-service";
import {PreLoginGuard} from "@app/routing-guards/pre-login.guard";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {LoadingSpinnerService} from "@app/shared/spinner/loading-spinner.service";
import {NotificationService} from "@app/core/notifications/notification.service";
import {AppErrorHandler} from "@app/core/error-handler/error-handler.service";
import {UserStateService} from "@app/core/auth/user-state.service";
import {SidenavService} from "@app/services/sidenav.service";
import {LeftMenuComponent} from "@app/left-menu/left-menu.component";
import { FlexLayoutModule } from '@angular/flex-layout';
import {UserIdleModule} from "angular-user-idle";
import {LeafletModule} from "@asymmetrik/ngx-leaflet";
import {LeafletDrawModule} from "@asymmetrik/ngx-leaflet-draw";
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {NgxImageCompressService} from "ngx-image-compress";
import { AgmCoreModule } from '@agm/core';
import {ChartjsModule} from "@ctrl/ngx-chartjs";
import {NgxEchartsModule} from "ngx-echarts";
import * as echarts from 'echarts';
import {DefaultLanguageService} from '@app/core/auth/default-language.service';
import {RememberMeService} from '@app/core/auth/remember-me.service';
import {LocationBasedHeaderService} from '@app/core/auth/location-based-header.service';
import {CommunicationServiceService} from '@app/services/communication-service.service';

export function HttpLoaderFactory(http: HttpClient) {
return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    ReactiveFormsModule,
    SpinnerModule,
    AppHeaderModule,
    HttpClientModule,
    FlexLayoutModule,
    UserIdleModule.forRoot({idle: 1800, timeout: 2, ping: null}),
    LeafletModule,
    LeafletDrawModule,
    ChartjsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot(),
    NgxEchartsModule.forRoot({
      echarts,
    }),
  ],
  declarations: [AppComponent, LeftMenuComponent],
  providers: [
    EventsService,
    AuthService,
    AuthGuard,
    PreLoginGuard,
    LoadingSpinnerService,
    NotificationService,
    AppErrorHandler,
    UserStateService,
    SidenavService,
    CommunicationServiceService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DefaultLanguageService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RememberMeService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LocationBasedHeaderService,
      multi: true
    },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
    NgxImageCompressService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
