import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler, HttpErrorResponse,
} from '@angular/common/http';
import {take, exhaustMap, catchError} from 'rxjs/operators';
import {UserStateService} from "@app/core/auth/user-state.service";
import {throwError} from "rxjs";
import {Router} from "@angular/router";
import {EventsService} from '@app/events/events.service';

@Injectable()
export class RememberMeService implements HttpInterceptor {

    rememberMe: boolean;

    constructor(private userStateService: UserStateService,
                private router: Router,
                private events: EventsService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        this.rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
        return this.userStateService.user$.pipe(
            take(1),
            exhaustMap(user => {
                const modifiedReq = req.clone({
                    headers: req.headers.set('rememberMe', `${this.rememberMe}`),
                });
                return next.handle(modifiedReq).pipe(catchError(this.handleError.bind(this)));
            })
        );
    }

    private handleError(errorRes: HttpErrorResponse) {
        if (errorRes && errorRes.status === 401
            && !this.router.url.includes('edit')
            && !this.router.url.includes('add')
            && !this.router.url.includes('change-password'))  {
            localStorage.clear();
            if (this.router.url !== '/sign-in' &&
                this.router.url !== '/forgot-password') {
                window.location.reload();
            }
        }
        return throwError(errorRes);
    }
}
