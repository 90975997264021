import {Component, OnInit} from '@angular/core';
import {animateText, onSideNavChange} from '@app/animations/animations';
import {SidenavService} from '@app/services/sidenav.service';
import {AuthService} from '@app/core/auth/auth.service';
import {UserStateService} from '@app/core/auth/user-state.service';
import {NotificationService} from '@app/core/notifications/notification.service';
import {filter, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {EventsService} from '@app/events/events.service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@env/environment';
import {NavigationEnd, Router} from '@angular/router';

interface Page {
    link: string;
    name: string;
    icon: string;
    iconType: string;
    toolBar?: string;
    iconUrl?: string;
    type?: string;
}

@Component({
    selector: 'app-left-menu',
    templateUrl: './left-menu.component.html',
    styleUrls: ['./left-menu.component.scss'],
    animations: [onSideNavChange, animateText]
})
export class LeftMenuComponent implements OnInit {

    public sideNavState: boolean = false;
    public linkText: boolean = false;
    public pages: Page[] = [];
    isAuthenticated$: Observable<boolean>;
    originalNavState: boolean = false;
    avatarImage$: Observable<string>;
    isGrower: boolean = false;
    isFarmIntelAdmin: boolean = false;
    activeSideMenu: string = '';
    defaultLanguage: string = environment.defaultLanguage;
    public example: string = 'false';
    disableMenu: boolean = false;
    sideBarState: boolean = false;
    isFarmIntelResourceManager: boolean = false;
    isFarmIntelContributor: boolean = false;
    isFarmIntelGrower: boolean = false;
    userId: number;
    openApprovalMenu: boolean = false;
    leftMenuEnabled: string;
    isSuperAdmin: boolean = false;
    isAdmin: boolean = false;
    isAdminExecutive: boolean = false;
    flagValue: boolean = true;
    currentLanguage: string;
    isLogin: string = '';

    constructor(private _sidenavService: SidenavService,
                private authService: AuthService,
                private userStateService: UserStateService,
                private notification: NotificationService,
                private events: EventsService,
                private translate: TranslateService,
                private router: Router) {
         
        this._sidenavService.masterNavMode$.subscribe((item) => {
            if (item) {
                this.originalNavState = true;
                this.sideNavState = true;
                setTimeout(() => {
                    this.linkText = this.sideNavState;
                }, 200)
                this.notifyChanges();
            } else {
                this.originalNavState = false;
                this.sideNavState = false;
                setTimeout(() => {
                    this.linkText = this.sideNavState;
                }, 200)
                this.notifyChanges();
            }
        });

        this._sidenavService.disableMenu$.subscribe((item) => {
            this.disableMenu = item;
        });
    }

    ngOnInit(): void {
        // this.currentLanguage = localStorage.getItem('currentLanguage');
        this.isAuthenticated$ = this.userStateService.isUserLoggedIn$
        //     .pipe(tap(item => {
        //         this.checkRoleAndRenderNavigationItems();
        //     }));

        this.pages = [
            {name: 'Dashboard', link: '/dashboard', icon: 'fa fa-bars menu-color', iconType: 'fa'},
            {name: 'Territory Manager Details', link: '/territory-manager-details', icon: 'fa fa-folder-o menu-color', iconType: 'fa'},
            {name: 'Distributor Details', link: '/distributor-details', icon: 'fa fa-folder-o menu-color', iconType: 'fa'},
            {name: 'History Details', link: '/history-details', icon: 'fa fa-history menu-color', iconType: 'fa'},
        ];
    }

    leftMenuActiveMethod(type: string, routing): void {
        if (type === 'farmetrix') {
            if (routing === '/dashboard/fields' || routing === '/field/add' || routing === '/field/edit' ||
                routing === '/crop-details/add' || routing === '/crop-details/edit' || routing === '/field-details' ||
                routing === '/crop-monitoring/dashboard' || routing === '/performance-curve' || (routing.includes('/drone-data'))) {
                this.activeSideMenu = 'Farm Management';
            } else if (routing.includes('/scouting')) {
                this.activeSideMenu = 'Scouting';
            } else if (routing.includes('/sustainability')) {
                this.activeSideMenu = 'Sustainability';
            } else if (routing === '/farmetrix/approval-flow/weeds' || routing === '/farmetrix/approval-flow/diseases' || routing === '/farmetrix/approval-flow/insects') {
                this.activeSideMenu = 'Approval Flow';
            } else if (routing.includes('/farmetrix')) {
                this.activeSideMenu = 'Dashboard';
            }
            else {
                this.activeSideMenu = null;
            }
        } else {
            if (routing === '/' || routing === '/dashboard' || routing === '/resource-managers' || routing === '/contributors'
                || routing === '/growers' || routing === '/distributor-retailer' || routing === '/uploaded-weeds' || routing === '/master-weeds'
                || routing.includes('/approval-flow') || routing === '/weed-statistics') {
                localStorage.setItem('leftMenuClick', routing);
            }
            this.leftMenuEnabled = localStorage.getItem('leftMenuClick');
            if (this.leftMenuEnabled === '/dashboard' || this.leftMenuEnabled === '/') {
                this.activeSideMenu = 'Dashboard';
            } else if (this.leftMenuEnabled === '/resource-managers') {
                this.activeSideMenu = 'Resource Managers';
            } else if (this.leftMenuEnabled === '/contributors') {
                this.activeSideMenu = 'Contributors';
            } else if (this.leftMenuEnabled === '/growers') {
                this.activeSideMenu = 'Growers';
            } else if (this.leftMenuEnabled === '/distributor-retailer') {
                this.activeSideMenu = 'Product Seller';
            } else if (this.leftMenuEnabled === '/approval-flow/weeds') {
                this.activeSideMenu = 'Approval Flow';
            } else if (this.leftMenuEnabled === '/weed-statistics') {
                this.activeSideMenu = 'Weed Statistics';
            } else if (this.leftMenuEnabled === '/uploaded-weeds') {
                if (this.defaultLanguage == 'es' || this.defaultLanguage == 'en') {
                    this.activeSideMenu = 'Weeds Uploaded';
                } else {
                    this.activeSideMenu = 'Rice Uploaded';
                }
            } else if (this.leftMenuEnabled === '/master-weeds') {
                if (this.defaultLanguage == 'es' || this.defaultLanguage == 'en') {
                    this.activeSideMenu = 'Master Weeds';
                } else {
                    this.activeSideMenu = 'Rice Master';
                }
            } else {
                this.activeSideMenu = '';
            }
        }
    }

    roleAndRoutingEvents(): void {
        // this.isFarmIntelAdmin = this.userStateService.isFarmIntelAdmin();
        this.isFarmIntelAdmin = this.userStateService.isAdmin();
        // this.isFarmIntelResourceManager = this.userStateService.isFarmIntelResourceManager();
        // this.isFarmIntelContributor = this.userStateService.isFarmIntelContributor();
        // this.isFarmIntelGrower = this.userStateService.isFarmIntelGrower();
        // this.isGrower = this.userStateService.isGrower();
        // this.isSuperAdmin = this.userStateService.isSuperAdmin();
        // this.isAdmin = this.userStateService.isAdmin();
        // this.isAdminExecutive = this.userStateService.isAdminExecutive();
        this.router.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(event => {
                if (this.flagValue) {
                    if (this.isFarmIntelAdmin || this.isFarmIntelResourceManager || this.isFarmIntelContributor || this.isFarmIntelGrower
                        || this.isGrower) {
                        this.leftMenuActiveMethod('farmetrix', event['url']);
                    } else {
                        if (event['url'] === '/uploaded-weeds') {
                            let queryParam = JSON.parse(localStorage.getItem('queryParams'));
                            if (queryParam) {
                                localStorage.removeItem('queryParams');
                            }
                        } else if (event['url'].includes('approval-flow')) {
                            this.openApprovalMenu = true;
                        }
                        this.leftMenuActiveMethod('others', event['url']);
                    }
                }
            });
    }

    checkRoleAndRenderNavigationItems(): void {
        this.openApprovalMenu = false;
        this.roleAndRoutingEvents();
        this.avatarImage$ = this.userStateService.currentImage$;
        if (this.isSuperAdmin) {
            this.pages = [
                {name: 'Dashboard', link: '/dashboard', icon: 'fa fa-dashboard dashboard-blue', iconType: 'fa'},
                {name: 'Master Weeds', link: '/master-weeds', icon: 'fa fa-pagelines weed-green', iconType: 'fa'},
                {name: 'Resource Managers', link: '/resource-managers', icon: '../../assets/admin.png', iconType: 'img'},
                {name: 'Contributors', link: '/contributors', icon: '../../assets/admin-exe.png', iconType: 'img'},
                {name: 'Growers', link: '/growers', icon: '../../assets/grower-icon.png', iconType: 'img'},

            ];
            if (!(this.defaultLanguage == 'en' || this.defaultLanguage == 'es')) {
                this.pages = [
                    {name: 'Dashboard', link: '/dashboard', icon: 'fa fa-dashboard dashboard-blue', iconType: 'fa'},
                    {name: 'Rice Master', link: '/master-weeds', icon: 'fa fa-pagelines weed-green', iconType: 'fa'},
                    {name: 'Resource Managers', link: '/resource-managers', icon: '../../assets/admin.png', iconType: 'img'},
                    {name: 'Contributors', link: '/contributors', icon: '../../assets/admin-exe.png', iconType: 'img'},
                    {name: 'Growers', link: '/growers', icon: '../../assets/grower-icon.png', iconType: 'img'},
                    {name: 'Product Seller', link: '/distributor-retailer', icon: '../../assets/distributor_retailer_image.png', iconType: 'img'},
                    {name: 'Approval Flow', link: '', icon: '../../assets/approval-flow-image.png', iconType: 'img'}
                ];
            } else {
                this.pages = [
                    {name: 'Dashboard', link: '/dashboard', icon: 'fa fa-dashboard dashboard-blue', iconType: 'fa'},
                    {name: 'Master Weeds', link: '/master-weeds', icon: 'fa fa-pagelines weed-green', iconType: 'fa'},
                    {name: 'Resource Managers', link: '/resource-managers', icon: '../../assets/admin.png', iconType: 'img'},
                    {name: 'Contributors', link: '/contributors', icon: '../../assets/admin-exe.png', iconType: 'img'},
                    {name: 'Growers', link: '/growers', icon: '../../assets/grower-icon.png', iconType: 'img'},
                    {name: 'Approval Flow', link: '/approval-flow/weeds', icon: '../../assets/approval-flow-image.png', iconType: 'img'},
                    {name: 'Weed Statistics', link: '/weed-statistics', icon: '../../assets/weed-statistics-image.png', iconType: 'img'}
                ];
            }
        }

        if (this.isAdmin) {
            this.pages = [
                {name: 'Dashboard', link: '/dashboard', icon: 'fa fa-dashboard dashboard-blue', iconType: 'fa'},
                {name: 'Master Weeds', link: '/master-weeds', icon: 'fa fa-pagelines weed-green', iconType: 'fa'},
                {name: 'Weeds Uploaded', link: '/uploaded-weeds', icon: 'fa fa-leaf weed-green', iconType: 'fa'},
                {name: 'Contributors', link: '/contributors', icon: '../../assets/admin-exe.png', iconType: 'img'},
                {name: 'Growers', link: '/growers', icon: '../../assets/grower-icon.png', iconType: 'img'},
            ];
            if (!(this.defaultLanguage == 'en' || this.defaultLanguage == 'es')) {
                this.pages = [
                    {name: 'Dashboard', link: '/dashboard', icon: 'fa fa-dashboard dashboard-blue', iconType: 'fa'},
                    {name: 'Rice Master', link: '/master-weeds', icon: 'fa fa-pagelines weed-green', iconType: 'fa'},
                    {name: 'Rice Uploaded', link: '/uploaded-weeds', icon: 'fa fa-leaf weed-green', iconType: 'fa'},
                    {name: 'Contributors', link: '/contributors', icon: '../../assets/admin-exe.png', iconType: 'img'},
                    {name: 'Growers', link: '/growers', icon: '../../assets/grower-icon.png', iconType: 'img'},
                    {name: 'Product Seller', link: '/distributor-retailer', icon: '../../assets/distributor_retailer_image.png', iconType: 'img'},
                    {name: 'Approval Flow', link: '', icon: '../../assets/approval-flow-image.png', iconType: 'img'}
                ];
            } else {
                this.pages = [
                    {name: 'Dashboard', link: '/dashboard', icon: 'fa fa-dashboard dashboard-blue', iconType: 'fa'},
                    {name: 'Master Weeds', link: '/master-weeds', icon: 'fa fa-pagelines weed-green', iconType: 'fa'},
                    {name: 'Weeds Uploaded', link: '/uploaded-weeds', icon: 'fa fa-leaf weed-green', iconType: 'fa'},
                    {name: 'Contributors', link: '/contributors', icon: '../../assets/admin-exe.png', iconType: 'img'},
                    {name: 'Growers', link: '/growers', icon: '../../assets/grower-icon.png', iconType: 'img'},
                    {name: 'Approval Flow', link: '/approval-flow/weeds', icon: '../../assets/approval-flow-image.png', iconType: 'img'}
                ];
            }
        }

        if (this.isAdminExecutive) {
            this.pages = [
                {name: 'Dashboard', link: '/dashboard', icon: 'fa fa-dashboard dashboard-blue', iconType: 'fa'},
                {name: 'Master Weeds', link: '/master-weeds', icon: 'fa fa-pagelines weed-green', iconType: 'fa'},
                {name: 'Weeds Uploaded', link: '/uploaded-weeds', icon: 'fa fa-leaf weed-green', iconType: 'fa'},
                {name: 'Growers', link: '/growers', icon: '../../assets/grower-icon.png', iconType: 'img'}
            ];
            if (!(this.defaultLanguage == 'en' || this.defaultLanguage == 'es')) {
                this.pages = [
                    {name: 'Dashboard', link: '/dashboard', icon: 'fa fa-dashboard dashboard-blue', iconType: 'fa'},
                    {name: 'Rice Master', link: '/master-weeds', icon: 'fa fa-pagelines weed-green', iconType: 'fa'},
                    {name: 'Rice Uploaded', link: '/uploaded-weeds', icon: 'fa fa-leaf weed-green', iconType: 'fa'},
                    {name: 'Growers', link: '/growers', icon: '../../assets/grower-icon.png', iconType: 'img'},
                    {name: 'Product Seller', link: '/distributor-retailer', icon: '../../assets/distributor_retailer_image.png', iconType: 'img'}
                ];
            } else {
                this.pages = [
                    {name: 'Dashboard', link: '/dashboard', icon: 'fa fa-dashboard dashboard-blue', iconType: 'fa'},
                    {name: 'Master Weeds', link: '/master-weeds', icon: 'fa fa-pagelines weed-green', iconType: 'fa'},
                    {name: 'Weeds Uploaded', link: '/uploaded-weeds', icon: 'fa fa-leaf weed-green', iconType: 'fa'},
                    {name: 'Growers', link: '/growers', icon: '../../assets/grower-icon.png', iconType: 'img'}
                ];
            }
        }

        if (this.isFarmIntelGrower || this.isGrower) {
            this.pages = [
                {
                    name: 'Farm Management', link: '/dashboard',
                    icon: '../../assets/growth.png', iconType: 'img', toolBar: 'toolbarUserMenu'
                },
                {
                    name: 'Scouting',
                    link: '/scouting-weed',
                    icon: '../../assets/loupe.png',
                    iconType: 'img',
                    toolBar: 'toolbarUserMenu'
                },
                {
                    name: 'Irrigation',
                    link: '/irrigation',
                    icon: '../../assets/water-drop.png',
                    iconType: 'img',
                    toolBar: ''
                },
                {
                    name: 'Sustainability',
                    link: '/sustainability',
                    icon: '../../assets/plant_nav.png',
                    iconType: 'img',
                    toolBar: 'toolbarUserMenu'
                },
                {
                    name: 'Weather',
                    link: '/weather',
                    icon: '../../assets/sun.png',
                    iconType: 'img',
                    toolBar: '',
                    type: 'WeatherType'
                },
                {
                    name: 'Market Price',
                    link: '/marketprice',
                    icon: '../../assets/money.png',
                    iconType: 'img',
                    toolBar: ''
                },
                {
                    name: 'Leaderboard',
                    link: '/field-leaderboard',
                    icon: '../../assets/leaderboard.png',
                    iconType: 'img',
                    toolBar: ''
                }
            ];
        }

        if (this.isFarmIntelAdmin || this.isFarmIntelResourceManager) {
            this.pages = [
                // {
                //     name: 'Farm Management', link: '/dashboard',
                //     icon: '../../assets/growth.png', iconType: 'img', toolBar: 'toolbarUserMenu'
                // },
                // {
                //     name: 'Scouting',
                //     link: '/scouting-weed',
                //     icon: '../../assets/loupe.png',
                //     iconType: 'img',
                //     toolBar: 'toolbarUserMenu'
                // },
                // {
                //     name: 'Irrigation',
                //     link: '/irrigation',
                //     icon: '../../assets/water-drop.png',
                //     iconType: 'img',
                //     toolBar: ''
                // },
                // {
                //     name: 'Sustainability',
                //     link: '/sustainability',
                //     icon: '../../assets/plant_nav.png',
                //     iconType: 'img',
                //     toolBar: 'toolbarUserMenu'
                // },
                // {
                //     name: 'Weather',
                //     link: '/weather',
                //     icon: '../../assets/sun.png',
                //     iconType: 'img',
                //     toolBar: '',
                //     type: 'WeatherType'
                // },
                // {
                //     name: 'Market Price',
                //     link: '/marketprice',
                //     icon: '../../assets/money.png',
                //     iconType: 'img',
                //     toolBar: ''
                // },
                // {
                //     name: 'Leaderboard',
                //     link: '/field-leaderboard',
                //     icon: '../../assets/leaderboard.png',
                //     iconType: 'img',
                //     toolBar: ''
                // },
                // {
                //     name: 'Approval Flow',
                //     link: '/approvalFlow',
                //     icon: '../../assets/approval-flow.png',
                //     iconType: 'img',
                //     toolBar: 'toolbarUserMenu'
                // },
                {
                    name: 'Dashboard',
                    link: '/UserManagement',
                    icon: '../../assets/admin_dashboard_left_menu.png',
                    iconType: 'img',
                    toolBar: 'toolbarUserMenu'
                },
            ];
        }

        if (this.isFarmIntelContributor) {
            this.pages = [
                {
                    name: 'Farm Management', link: '/dashboard',
                    icon: '../../assets/growth.png', iconType: 'img', toolBar: 'toolbarUserMenu'
                },
                {
                    name: 'Scouting',
                    link: '/scouting-weed',
                    icon: '../../assets/loupe.png',
                    iconType: 'img',
                    toolBar: 'toolbarUserMenu'
                },
                {
                    name: 'Irrigation',
                    link: '/irrigation',
                    icon: '../../assets/water-drop.png',
                    iconType: 'img',
                    toolBar: ''
                },
                {
                    name: 'Sustainability',
                    link: '/sustainability',
                    icon: '../../assets/plant_nav.png',
                    iconType: 'img',
                    toolBar: 'toolbarUserMenu'
                },
                {
                    name: 'Weather',
                    link: '/weather',
                    icon: '../../assets/sun.png',
                    iconType: 'img',
                    toolBar: '',
                    type: 'WeatherType'
                },
                {
                    name: 'Market Price',
                    link: '/marketprice',
                    icon: '../../assets/money.png',
                    iconType: 'img',
                    toolBar: ''
                },
                {
                    name: 'Leaderboard',
                    link: '/field-leaderboard',
                    icon: '../../assets/leaderboard.png',
                    iconType: 'img',
                    toolBar: ''
                },
                {
                    name: 'Dashboard',
                    link: '/UserManagement',
                    icon: '../../assets/admin_profile.png',
                    iconType: 'img',
                    toolBar: 'toolbarUserMenu'
                },
            ];
        }
    }

    openSideBar(type?: string): void {
        this.sideNavState = true;
        setTimeout(() => {
            this.linkText = this.sideNavState;
        }, 200)
        this.notifyChanges();
    }

    closeSideBar(type?: string): void {
        if (!this.originalNavState) {
            this.sideNavState = false;
            setTimeout(() => {
                this.linkText = this.sideNavState;
            }, 200)
            this.notifyChanges();
        }
    }

    notifyChanges(): void {
        this._sidenavService.sideNavState$.next(this.sideNavState);
    }

    checkValue(type: string): void {
        this.example = 'false';
    }

    action(page: Page): boolean {
        this.example = 'false';
        if (page && page.name) {
            switch (page.name) {
                case 'Logout': {
                    this.authService.clearCurrentUserState();
                    break;
                }
                case 'Weather': {
                    this.example = 'true';
                    this.router.navigate(['/field-details'])
                    setTimeout(() => {
                        this.events.managePanels('open');
                    }, 200);
                    break;
                }
                case 'Approval Flow': {
                    this.openApprovalMenu = !this.openApprovalMenu;
                    break;
                }
                default: {
                    this.notification.warn(this.translate.instant('Work in progress'));
                    return;
                }
            }
        }
        return;
    }

    toggleSideBar() {
        this.sideBarState = !this.sideBarState;
        this._sidenavService.masterNavMode$.next(this.sideBarState);
    }

    displayCarbonScreen(): void {
        this.notification.warn(this.translate.instant('Work in progress'));
    }

    highLightMenu(menuSelected): boolean {
        return this.router.url == menuSelected ? true : false;
    }

    navigateUserList(): void {
        localStorage.setItem('currentCropValue', JSON.stringify(true));
        this.userId = this.userStateService.currentAppUserId();
        localStorage.setItem("currentUserId", JSON.stringify(this.userId));
    }

    redirectToDashboard(type: string): void {
        this.router.navigate(['/dashboard']);
    }

    navigateApprovalScreen(type: string): void {
        this.router.navigate([`/approval-flow/${type}`]);
    }

    otherLeftMenu(): void {
        this.openApprovalMenu = false;
    }
}
