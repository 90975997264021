export class AppConstant {
    public static ADMIN_ROLE: string = 'ADMIN';
    public static SUPER_ADMIN_ROLE: string = 'SUPER_ADMIN';
    public static ADMIN_EXECUTIVE_ROLE: string = 'ADMIN_EXECUTIVE';
    public static GROWER_ROLE: string = 'GROWER';
    public static FARMINTEL_ADMIN_ROLE: string = 'FARMINTEL_ADMIN';
    public static DISTRIBUTOR_ROLE: string = 'DISTRIBUTOR';
    public static RETAILER_ROLE: string = 'RETAILER';
    public static OTHER_ROLE: string = 'OTHER';
    public static FARMINTEL_RESOURCE_MANAGER_ROLE: string = 'FARMINTEL_RESOURCE_MANAGER';
    public static FARMINTEL_CONTRIBUTOR_ROLE: string = 'FARMINTEL_CONTRIBUTOR';
    public static FARMINTEL_GROWER_ROLE: string = 'FARMINTEL_GROWER';

    public static ADMIN_COMPONENT: string = 'ADMIN_COMPONENT';
    public static ADMIN_EXECUTIVE_COMPONENT: string = 'ADMIN_EXECUTIVE_COMPONENT';
    public static GROWER_COMPONENT: string = 'GROWER_COMPONENT';
    public static DEMOGRAPHIC_TAB_NAME: string = 'demographicTab';
    public static REGIONAL_TAB_NAME: string = 'regionalNameTab';
    public static WEED_TAB_NAME: string = 'weedTab';
    public static DISTRIBUTOR_RETAILER_COMPONENT: string = 'DISTRIBUTOR_RETAILER_COMPONENT';
}
