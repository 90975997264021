import {EventEmitter, Injectable, Output} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EventsService {
    cropDataSet: Object;
    growerDataSet: Object;
    inActiveResetSet: Object;
    deleteUserSet: boolean;
    @Output()
    appHeader: EventEmitter<any> = new EventEmitter(true);
    setSideBarBgColor: EventEmitter<any> = new EventEmitter(false);
    breadcrumbs: EventEmitter<any> = new EventEmitter(true);
    setFieldId: EventEmitter<number> = new EventEmitter(true);

    displayGrowerField$: Subject<boolean> = new Subject<boolean>();
    private showHidePanel = new Subject<string>();
    managePanel$ = this.showHidePanel.asObservable();

    constructor() {
    }

    displayGrowerFieldsPopUp(display: boolean) {
        this.displayGrowerField$.next(display);
    }

    managePanels(msg: string) {
        this.showHidePanel.next(msg);
    }

    setGrowerData(data) {
        this.growerDataSet = data;
    }

    setInActiveData(msg) {
        this.inActiveResetSet = msg;
    }

    setDeleteUserData(msg) {
        this.deleteUserSet = msg;
    }
}
