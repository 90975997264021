import { MatPaginatorIntl } from '@angular/material/paginator';
import { environment } from '@env/environment';

const SpanishRangeLabel = (page: number, pageSize: number, length: number) => {
  if (length == 0 || pageSize == 0) { return `0 van ${length}`; }
  
  length = Math.max(length, 0);

  const startIndex = page * pageSize;

  // If the start index exceeds the list length, do not try and fix the end index to the end.
  const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;

  return `${startIndex + 1} - ${endIndex} van ${length}`;
}


export function getSpanishPaginatorIntl() {
  const paginatorIntl = new MatPaginatorIntl();
  const currentLanguage: string =  localStorage.getItem('currentLanguage');
  if (currentLanguage == 'in') {
    paginatorIntl.itemsPerPageLabel = 'Item per halaman:';
    paginatorIntl.nextPageLabel = 'Halaman selanjutnya';
    paginatorIntl.previousPageLabel = 'Halaman sebelumnya';
    paginatorIntl.firstPageLabel = 'Halaman pertama';
    paginatorIntl.lastPageLabel = 'Halaman terakhir';
    paginatorIntl.getRangeLabel = SpanishRangeLabel;
  }
  if (currentLanguage == 'vi') {
    paginatorIntl.itemsPerPageLabel = 'Các mục trên mỗi trang:';
    paginatorIntl.nextPageLabel = 'Trang tiếp theo';
    paginatorIntl.previousPageLabel = 'Trang trước';
    paginatorIntl.firstPageLabel = 'Trang đầu tiên';
    paginatorIntl.lastPageLabel = 'Trang cuối';
    paginatorIntl.getRangeLabel = SpanishRangeLabel;
  }
  if (currentLanguage == 'en') {
    paginatorIntl.itemsPerPageLabel = 'Items per page:';
    paginatorIntl.nextPageLabel = 'Next page';
    paginatorIntl.previousPageLabel = 'Previous page';
    paginatorIntl.firstPageLabel = 'First page';
    paginatorIntl.lastPageLabel = 'Last page';
    paginatorIntl.getRangeLabel = SpanishRangeLabel;
  }
  if (currentLanguage == 'ur') {
    paginatorIntl.itemsPerPageLabel = 'اشیاء فی صفحہ:';
    paginatorIntl.nextPageLabel = 'اگلا صفحہ';
    paginatorIntl.previousPageLabel = 'سابقہ صفحہ';
    paginatorIntl.firstPageLabel = 'پہلا صفحہ';
    paginatorIntl.lastPageLabel = 'اخری صفحہ';
    paginatorIntl.getRangeLabel = SpanishRangeLabel;
  }
  if (currentLanguage == 'th') {
    paginatorIntl.itemsPerPageLabel = 'รายการต่อหน้า:';
    paginatorIntl.nextPageLabel = 'หน้าต่อไป';
    paginatorIntl.previousPageLabel = 'หน้าก่อน';
    paginatorIntl.firstPageLabel = 'หน้าแรก';
    paginatorIntl.lastPageLabel = 'หน้าสุดท้าย';
    paginatorIntl.getRangeLabel = SpanishRangeLabel;
  }
  if (currentLanguage == 'fil') {
    paginatorIntl.itemsPerPageLabel = 'Mga item sa bawat pahina:';
    paginatorIntl.nextPageLabel = 'Susunod na pahina';
    paginatorIntl.previousPageLabel = 'Nakaraang pahina';
    paginatorIntl.firstPageLabel = 'Unang pahina';
    paginatorIntl.lastPageLabel = 'Huling pahina';
    paginatorIntl.getRangeLabel = SpanishRangeLabel;
  }

  return paginatorIntl;
}
