import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {EventsService} from '@app/events/events.service';
import {AdminService} from '@app/services/admin.service';
import {AuthService} from '@app/core/auth/auth.service';
import {UserStateService} from '@app/core/auth/user-state.service';
import {MatSidenav} from '@angular/material/sidenav';
import {SidenavService} from '@app/services/sidenav.service';
import {Observable, Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@env/environment';
import {LoadingSpinnerService} from '@app/shared/spinner/loading-spinner.service';
import * as _ from 'lodash';
import {QueryParam, Search, SearchWithPagination} from '@app/shared/util/request-util';
import {NotificationService} from '@app/core/notifications/notification.service';
import {MultiLanguageModel} from '@app/shared/multi language/multi-language.model';
import {CommunicationServiceService} from '@app/services/communication-service.service';

@Component({
    selector: 'app-header',
    templateUrl: './app-header.component.html',
    styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {
    logo = 'https://in.uplonline.com/images/logo.png';
    appHeader: string;
    fullName: string;
    @Input() sidenav: MatSidenav;
    previewImage = '';
    sideBarState = false;
    avatarImage$: Observable<string>;
    defaultLanguage: string = environment.defaultLanguage;
    toggle = false;
    translationToggle = false;
    sortButtonClicked = false;
    globeButtonClicked = false;
    englishFlag = false;
    indonesiaFlag = false;
    spanishFlag = false;
    vietnamFlag = false;
    currentLanguage: string;
    breadcrumb: any;
    appUserId: number;
    pageSize = 100;
    growerFields: any[] = [];
    firstFieldId: number;
    routerSubscription: Subscription;
    setFieldIdEventSubscription: Subscription;
    notificationCountSubscription: Subscription;
    appHeadingFlag: boolean;
    userCropId: string;
    field_Id: string;
    userName: string;
    selectedFieldImageUrl: string;
    // isFarmIntelAdmin: boolean = this.userStateService.isFarmIntelAdmin();
    isFarmIntelAdmin: boolean = this.userStateService.isAdmin();
    isFarmIntelResourceManager: boolean = this.userStateService.isFarmIntelResourceManager();
    isFarmIntelContributor: boolean = this.userStateService.isFarmIntelContributor();
    isFarmIntelGrower: boolean = this.userStateService.isFarmIntelGrower();
    isGrower: boolean = this.userStateService.isGrower();
    currentCountryName: string;
    token: string;
    currentCountryType: string;
    backUrl: string;
    farmetrixFlag: boolean;
    languageList: MultiLanguageModel[] = [
        {name: 'English', value: 'en', flag: false, countryFlag: 'in'},
        {name: 'Ukraine', value: 'uk', flag: false, countryFlag: 'ua'},
        // {name: 'Portuguese', value: 'pt', flag: false, countryFlag: 'br'}
    ]
    esLanguageList: MultiLanguageModel[] = [
        {name: 'English', value: 'en', flag: false, countryFlag: 'in'},
        {name: 'Español', value: 'es', flag: false, countryFlag: 'ar'}
    ]
    inLanguageList: MultiLanguageModel[] = [
        {name: 'English', value: 'en', flag: false, countryFlag: 'in'},
        {name: 'Bahasa', value: 'in', flag: false, countryFlag: 'id'}
    ]
    viLanguageList: MultiLanguageModel[] = [
        {name: 'English', value: 'en', flag: false, countryFlag: 'in'},
        {name: 'Tiếng Việt', value: 'vi', flag: false, countryFlag: 'vn'}
    ]
    urLanguageList: MultiLanguageModel[] = [
        {name: 'English', value: 'en', flag: false, countryFlag: 'in'},
        {name: 'پاکستان', value: 'ur', flag: false, countryFlag: 'pk'}
    ]
    thLanguageList: MultiLanguageModel[] = [
        {name: 'English', value: 'en', flag: false, countryFlag: 'in'},
        {name: 'ประเทศไทย', value: 'th', flag: false, countryFlag: 'th'}
    ]
    filLanguageList: MultiLanguageModel[] = [
        {name: 'English', value: 'en', flag: false, countryFlag: 'in'},
        {name: 'Pilipinas', value: 'fil', flag: false, countryFlag: 'ph'}
    ]
    list: MultiLanguageModel[];
    notificationsCount: number;
    private readonly defaultNotificationsQueryParams: QueryParam[] = [
        {key: 'status.equals', value: true}
    ];
    notificationsQueryParams: QueryParam[] = _.clone(this.defaultNotificationsQueryParams);
    private readonly defaultAdminQueryParams: QueryParam[] = [
        {key: 'status.equals', value: true}
    ];
    queryParams: QueryParam[] = _.clone(this.defaultAdminQueryParams);

    constructor(private router: Router, private events: EventsService,
                private route: ActivatedRoute, private cdRef: ChangeDetectorRef,
                private adminService: AdminService, private authService: AuthService,
                private userStateService: UserStateService,
                private communicationService: CommunicationServiceService,
                private _sidenavService: SidenavService,
                private translate: TranslateService,
                private loadingSpinner: LoadingSpinnerService,
                private notificationService: NotificationService) {
        // if (this.isFarmIntelAdmin) {
            // this.firstFieldId = JSON.parse(localStorage.getItem('fieldId'));
            // this.routerSubscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd))
            //     .subscribe((event: any) => {
            //         this.appHeadingFlag = (
            //             (event['url'] === '/drone-data') ||
            //             (event['url'] === '/drone-data/new-upload') ||
            //             event['url'].includes('/marketprice') ||
            //             event['url'].includes('/weather') ||
            //             (event['url'] === '/sustainability/ghg-emission') ||
            //             (event['url'] === '/sustainability/ghg-calculator') ||
            //             event['url'].includes('/irrigation') ||
            //             event['url'].includes('/crop-monitoring') ||
            //             event['url'].includes('/performance-curve') ||
            //             (event['url'] === '/scouting/insect') ||
            //             (event['url'] === '/scouting/disease') ||
            //             (event['url'] === '/scouting/weed') ||
            //             (event['url'] === '/field-details') ||
            //             (event['url'] === '/field-boundary') ||
            //             (event['url'] === '/field-dashboard')
            //         );
            //     });
        // }
        this.farmetrixFlag = true;
    }

    get isAdminToken() {
        return !!localStorage.getItem('adminToken');
    }

    ngOnInit(): void {

        this.communicationService.changeHeader.subscribe((item: string) => {
            this.appHeader = item;
        });

        this.userStateService.currentImage$.subscribe(item => {
            if (item) {
                this.previewImage = item;
            }
            this.checkUserRoles();
        });
        this.toggle = true;
        this.translationToggle = true;
        this.currentLanguage = localStorage.getItem('currentLanguage');
        if (this.currentLanguage === null) {
            this.currentLanguage = environment.defaultLanguage;
        } else {
            localStorage.setItem('currentLanguage', this.currentLanguage);
        }
        this.multiLanguage(this.currentLanguage);
        this.events.managePanel$.subscribe(msg => {
            this.checkUserRoles();
        });
        let userDetailsJSON = localStorage.getItem('userData');
        let userDetails = JSON.parse(userDetailsJSON);
        this.userName = userDetails ? userDetails.firstName : '';
    }

    checkUserRoles(): void {
        this.avatarImage$ = this.userStateService.currentImage$;
        const profileData = JSON.parse(localStorage.getItem('profileData'));
        this.fullName = profileData && profileData.firstName ? profileData.firstName : 'Admin';
        this.previewImage = profileData ? profileData.profilePhotoPath : '';
        this.currentCountryName = localStorage.getItem('currentCountry');
        // if (this.currentCountryName === null || this.currentCountryName === undefined) {
        //     this.currentCountryName = this.userStateService.fetchSpecificCurrentUserDetails('countryName');
        // }
    }

    toggleSideBar() {
        this.sideBarState = !this.sideBarState;
        this._sidenavService.masterNavMode$.next(this.sideBarState);
    }

    fetchAdminToken() {
        const adminToken = localStorage.getItem('adminToken');
        this.authService.handoverBackToAdmin(adminToken)
    }

    onLogOut(): void {
        localStorage.clear();
        // this.translate.use(this.defaultLanguage);
        // localStorage.removeItem('currentLanguage');
        // localStorage.removeItem('currentCountry');
        this.authService.logout();
    }

    close(): void {
        if (!this.toggle && this.sortButtonClicked) {
            this.toggle = false;
            this.sortButtonClicked = false;
        } else {
            this.toggle = true;
        }
    }

    languageTranslation(response: string): void {
        this.loadingSpinner.show();
        localStorage.setItem('currentLanguage', response);
        this.multiLanguage(response);
        window.location.reload();
    }

    multiLanguage(response): void {
        if (this.defaultLanguage === 'en') {
            this.list = this.languageList;
        } else if (this.defaultLanguage === 'es') {
            this.list = this.esLanguageList;
        } else if (this.defaultLanguage === 'vi') {
            this.list = this.viLanguageList;
        } else if (this.defaultLanguage === 'in') {
            this.list = this.inLanguageList;
        } else if (this.defaultLanguage === 'ur') {
            this.list = this.urLanguageList;
        } else if (this.defaultLanguage === 'th') {
            this.list = this.thLanguageList;
        } else if (this.defaultLanguage === 'fil') {
            this.list = this.filLanguageList;
        }
        this.list.forEach(item => {
            if (item.value === response) {
                item.flag = true;
            }
        })
        this.translate.use(response);
    }

    countryMethod(type: string): void {
        if (type === 'India') {
            window.open('https://digitaldev.upl-ltd.com/', 'blank');
        } else {
            this.currentCountryType = localStorage.getItem('currentCountry');
            if (type === this.currentCountryType || type === this.currentCountryName) {
                this.notificationService.warn(this.translate.instant(`${type} is already selected`));
            } else {
                this.token = localStorage.getItem('adminToken');
                localStorage.setItem('currentCountry', type);
                if (this.token) {
                    this.fetchAdminToken();
                } else {
                    const routeUrl = this.route.snapshot['_routerState'].url;
                    this.currentCountryName = type;
                    if (!(routeUrl === '/dashboard' || routeUrl === '/master-weeds' || routeUrl === '/weed-lists' || routeUrl === '/resource-managers' ||
                        routeUrl === '/contributors' || routeUrl === '/growers' || routeUrl === '/approval-flow/weeds')) {
                        this.backUrl = localStorage.getItem('leftMenuClick');
                        this.router.navigate([this.backUrl]);
                    } else {
                        window.location.reload();
                    }
                }
            }
        }
    }

    navigateToCountries(countriesCode): void {
        if (countriesCode === 'in') {
            window.open('https://in-digitaldev.upl-ltd.com');
        } else if (countriesCode === 'fil') {
            window.open('https://ph-digitaldev.upl-ltd.com');
        } else if (countriesCode === 'th') {
            window.open('https://th-digitaldev.upl-ltd.com');
        } else if (countriesCode === 'ur') {
            window.open('https://pk-digitaldev.upl-ltd.com');
        } else if (countriesCode === 'vi') {
            window.open('https://vn-digitaldev.upl-ltd.com');
        }
    }
}
