import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler, HttpErrorResponse,
} from '@angular/common/http';
import {take, exhaustMap, catchError} from 'rxjs/operators';
import {UserStateService} from "@app/core/auth/user-state.service";
import {throwError} from "rxjs";
import {Router} from "@angular/router";

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
    constructor(private userStateService: UserStateService,
                private router: Router) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        return this.userStateService.user$.pipe(
            take(1),
            exhaustMap(user => {
                if (!user) {
                    return next.handle(req)
                        .pipe(catchError(this.handleError.bind(this)));
                }
                const modifiedReq = req.clone({
                    headers: req.headers.set('Authorization', `Bearer ${user.token}`)
                });
                return next.handle(modifiedReq).pipe(catchError(this.handleError.bind(this)));
            })
        );
    }

    private handleError(errorRes: HttpErrorResponse) {
        if (errorRes && errorRes.status === 401
            && !this.router.url.includes('edit')
            && !this.router.url.includes('add')
            && !this.router.url.includes('change-password'))  {
            localStorage.clear();
            if (this.router.url !== '/sign-in' &&
                this.router.url !== '/forgot-password') {
                window.location.reload();
            }
        }
        return throwError(errorRes);
    }
}
