import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";

@Injectable()
export class LoadingSpinnerService {
    private toggleSpinnerSubject: Subject<boolean> = new Subject<boolean>();
    showLoadingSpinner$: Observable<boolean> = this.toggleSpinnerSubject.asObservable();
    constructor() {}
    show() {
        this.toggleSpinnerSubject.next(true);
    }
    hide() {
        this.toggleSpinnerSubject.next(false);
    }
}
