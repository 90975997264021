import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {User} from "@app/core/auth/user.model";
import {AppConstant} from "@app/app.constant";

@Injectable()
export class UserStateService {
    private userSubject = new BehaviorSubject<User>(null);
    user$: Observable<User> = this.userSubject.asObservable();

    private isUserLoggedInSubject = new BehaviorSubject<boolean>(null);
    isUserLoggedIn$: Observable<boolean> = this.isUserLoggedInSubject.asObservable();

    private isAdminSubject = new BehaviorSubject<boolean>(null);
    isAdmin$: Observable<boolean> = this.isAdminSubject.asObservable();

    private isSuperAdminSubject = new BehaviorSubject<boolean>(null);
    isSuperAdmin$: Observable<boolean> = this.isSuperAdminSubject.asObservable();

    private isAdminExecutiveSubject = new BehaviorSubject<boolean>(null);
    isAdminExecutive$: Observable<boolean> = this.isAdminExecutiveSubject.asObservable();

    private currentUserRoleSubject = new BehaviorSubject<string>(null);
    currentUserRole$: Observable<string> = this.currentUserRoleSubject.asObservable();

    private currentImage = new BehaviorSubject<string>(null);
    currentImage$: Observable<string> = this.currentImage.asObservable();

    constructor() { }

    isAdmin(): boolean {
        return this.getCurrentUserRole() === AppConstant.ADMIN_ROLE
    }

    isSuperAdmin(): boolean {
        return this.getCurrentUserRole() === AppConstant.SUPER_ADMIN_ROLE
    }

    isAdminExecutive(): boolean {
       return this.getCurrentUserRole() === AppConstant.ADMIN_EXECUTIVE_ROLE;
    }

    isGrower(): boolean {
        return this.getCurrentUserRole() === AppConstant.GROWER_ROLE
    }

    isFarmIntelAdmin(): boolean {
        return this.getCurrentUserRole() === AppConstant.FARMINTEL_ADMIN_ROLE
    }

    isFarmIntelResourceManager(): boolean {
        return this.getCurrentUserRole() === AppConstant.FARMINTEL_RESOURCE_MANAGER_ROLE
    }

    isFarmIntelContributor(): boolean {
        return this.getCurrentUserRole() === AppConstant.FARMINTEL_CONTRIBUTOR_ROLE
    }

    isFarmIntelGrower(): boolean {
        return this.getCurrentUserRole() === AppConstant.FARMINTEL_GROWER_ROLE
    }

    currentLatitude() {
       return this.cloneUserData()['latitude'];
    }

    currentLongitude() {
        return this.cloneUserData()['longitude'];
    }

    currentAppUserId() {
        return this.cloneUserData()['id'];
    }

    getUserId(): number {
      const userData: User = this.cloneUserData();
      if(userData && userData.id)
        return userData.id;
      return null;
    }

    fetchSpecificCurrentUserDetails(itemName: string) {
        const userData: User = this.cloneUserData();
        if (itemName && userData.hasOwnProperty(itemName)) {
            return userData[itemName];
        }
        return null;
    }

    fetchImageDetails() {
        const imageData: string = this.cloneImageData();
        return imageData;
    }

    notifyAll(state) {
        this.notifyTheCurrentUserState(state);
    }

    notifyImage(image) {
        this.currentImage.next(image);
    }

    public getCurrentUserRole(): string {
        const userData: User = this.cloneUserData();
        if (userData && userData.role) {
            return userData.role;
        } else {
            return AppConstant.FARMINTEL_ADMIN_ROLE;
        }
    }

    isLoggedIn(): boolean {
        const userData: User = this.cloneUserData();
        if (!!userData &&
            userData.hasOwnProperty('token') &&
            userData.token) {
            return true;
        } else {
            return false;
        }
    }

    get previousUrl(): string {
        return localStorage.getItem('prvUrl');
    }

    get weedPreviousUrl(): string {
        return localStorage.getItem('weedPrvUrl');
    }

    private cloneImageData() {
        return this.currentImage.getValue();
    }

    private cloneUserData() {
        return this.userSubject.getValue();
    }

    private notifyTheCurrentUserState(state) {
        this.userSubject.next(state);
        this.notifyIsLoggedIn();
        this.notifyIsAdmin();
        // this.notifyIsSuperAdmin();
        // this.notifyIsAdminExecutive();
        this.notifyCurrentUserRole();
        // if (state) {
        //     this.notifyImage(state.profilePhotoPath);
        // }
    }

    private notifyIsAdmin(): void {
        this.isAdminSubject.next(this.isAdmin());
    }

    private notifyIsSuperAdmin(): void {
        this.isSuperAdminSubject.next(this.isSuperAdmin());
    }

    private notifyIsAdminExecutive(): void {
        this.isAdminExecutiveSubject.next(this.isAdminExecutive());
    }

    private notifyIsLoggedIn(): void {
        this.isUserLoggedInSubject.next(this.isLoggedIn());
    }

    private notifyCurrentUserRole(): void {
        const userRole: String = this.getCurrentUserRole();
        console.log(userRole);
        let role: string = null;
        switch (userRole) {
            case AppConstant.ADMIN_ROLE: {
                role =  'ADMIN';
                break;
            }
            case AppConstant.SUPER_ADMIN_ROLE: {
                role = 'SUPER ADMIN';
                break;
            }
            case AppConstant.ADMIN_EXECUTIVE_ROLE: {
                role = 'ADMIN EXECUTIVE';
                break;
            }
            default: {
                role =  null;
            }
        }
        this.currentUserRoleSubject.next(role);
    }
}
