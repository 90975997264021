import { Injectable, ErrorHandler } from '@angular/core';
import { NotificationService } from '../notifications/notification.service';
import {AuthService} from "@app/core/auth/auth.service";
import {LoadingSpinnerService} from "@app/shared/spinner/loading-spinner.service";
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';

declare const $: any;

@Injectable()
export class AppErrorHandler  {
    constructor(private notification: NotificationService,
                private authService: AuthService,
                private loadingSpinnerService: LoadingSpinnerService,
                private translate: TranslateService) {
      }
    

    commonError(err) {
        if (err.error.success === false) {
            this.notification.error(err.error.message);
        } else if (err && err.error && err.error.error && err.error.error.code === -104) {
            this.notification.error(this.translate.instant('Unauthorized user'));
            this.authService.clearCurrentUserState();
        } else if (err && err.error && err.error.error && err.error.error.code === -102) {
            this.notification.error(this.translate.instant('Unauthorized user'));
            this.authService.clearCurrentUserState();
        } else if (err && err.error && err.error.error && err.error.error.message) {
            this.notification.error(err.error.error.message);
        } else {
            this.doesConnectionExist().then((status: any) => {
                if (status) {
                    this.notification.error(this.translate.instant('Server Error..!!'));
                }
            }).catch( (err) => {
                this.notification.error(this.translate.instant('No Internet Connectivity. Please recheck and try again'));
            });
        }
        this.loadingSpinnerService.hide();
    }

    doesConnectionExist() {
        return new Promise((resolve, reject) => {
            const randomNum = Math.round(Math.random() * 10000);
            $.ajax({
                type: 'GET',
                url: `https://www.google.com?rand=${randomNum}`,
                dataType: 'jsonp',
                timeout: 3000,
                success() {
                    resolve(true);
                },
                error(XMLHttpRequest, textStatus) {
                    if (textStatus === 'timeout' || textStatus === 'error') {
                        reject(false);
                    } else if (XMLHttpRequest.status < 304 || textStatus === 'load') {
                        resolve(true);
                    } else {
                        resolve(true);
                    }
                }
            });
        });
    }
}
