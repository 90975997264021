import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { catchError, filter, map, tap } from "rxjs/operators";

export interface SsoInfo {
  loginUrl: string;
  logoutUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  baseURL: string = environment.baseURL;
  header: any;
  token: any = '';
  excel: any;
  private readonly plantProfitCenter: string = environment.baseURL + '/api/plant-profit-centers/';
  constructor(private http: HttpClient) {
    this.excel = new HttpHeaders({
      'Content-Type': 'application/vnd.ms-excel'
    });
  }


  getQuery(filterData) {
    let queryArray = [];
    for (const [key, value] of Object.entries(filterData)) {
      queryArray.push(`${key}=${value}`);
    }
    return queryArray.join('&');
  }

  fetchAuthToken(email): Observable<any> {
    var httpRequestHeaders = {}
    Object.assign(httpRequestHeaders, { accept: 'application/json, text/plain, */*' })
    return this.http.post(this.baseURL + "/api/handover/login?email=" + email, httpRequestHeaders)
  }

  fetchSsoInfo(): Observable<SsoInfo> {
    return this.http.get<SsoInfo>(`${this.baseURL}/api/auth-info`).pipe(
      catchError(this.handleError)
    );
  }

  fetchUserTokenUsingSsoInfo(requestBody): Observable<any> {
    return this.http.post(`${this.baseURL}/api/authenticate`, requestBody).pipe(
      catchError(this.handleError));
  }

  growerLogin(requestBody): Observable<any> {
    return this.http.post(`${this.baseURL}/api/grower/login`, requestBody).pipe(
      catchError(this.handleError));
  }

  updatePassword(requestBody): Observable<any> {
    return this.http.post(`${this.baseURL}/api/update-password`, requestBody).pipe(
      catchError(this.handleError));
  }

  growerSignup(requestBody): Observable<any> {
    return this.http.post(`${this.baseURL}/api/mobiles/signup`, requestBody).pipe(
      catchError(this.handleError));
  }

  signInUsingOtp(requestBody): Observable<any> {
    return this.http.post(`${this.baseURL}/api/mobiles/login-otp`, requestBody).pipe(
      catchError(this.handleError));
  }

  fetchCurrentMonthGraph(filterData): any {
    let queryString = this.getQuery(filterData);
    return this.http.get<any>(`${this.plantProfitCenter}count/currentMonth?${queryString}`)
      .pipe(catchError(this.handleError));
  }

  fetchProfitCenter(): any {
    return this.http.get<any>(`${this.plantProfitCenter}list/profitCenters`)
      .pipe(catchError(this.handleError));
  }

  fetchPreviousMonthGraph(filterData): any {
    let queryString = this.getQuery(filterData);
    return this.http.get<any>(`${this.plantProfitCenter}count/previousMonth?${queryString}`)
      .pipe(catchError(this.handleError));
  }

  fetchTotalScanned(filterData): any {
    let queryString = this.getQuery(filterData);
    return this.http.get<any>(`${this.plantProfitCenter}totalScanned?${queryString}`)
      .pipe(catchError(this.handleError));
  }

  fetchTotalUnScanned(filterData): any {
    let queryString = this.getQuery(filterData);
    return this.http.get<any>(`${this.plantProfitCenter}totalUnscanned?${queryString}`)
      .pipe(catchError(this.handleError));
  }

  fetchAllDepotOBDCount(filterData): Observable<any> {
    let queryString = this.getQuery(filterData);
    return this.http.get<any>(`${this.plantProfitCenter}graph/allDepotOBDCount?${queryString}`)
      .pipe(catchError(this.handleError));
  }

  fetchOBDCount(filterData): Observable<any> {
    let queryString = this.getQuery(filterData);
    return this.http.get<any>(`${this.plantProfitCenter}graph/obdCount?${queryString}`)
      .pipe(catchError(this.handleError));
  }

  fetchOverallScanned(filterData): Observable<any> {
    let queryString = this.getQuery(filterData);
    return this.http.get<any>(`${this.plantProfitCenter}graph/overallScanned?${queryString}`)
      .pipe(catchError(this.handleError));
  }

  fetchOverallUnscanned(filterData): Observable<any> {
    let queryString = this.getQuery(filterData);
    return this.http.get<any>(`${this.plantProfitCenter}graph/overallUnscanned?${queryString}`)
      .pipe(catchError(this.handleError));
  }

  fetchScannedVsDispatched(filterData): Observable<any> {
    let queryString = this.getQuery(filterData);
    return this.http.get<any>(`${this.plantProfitCenter}graph/scannedVsDispatched?${queryString}`)
      .pipe(catchError(this.handleError));
  }

  fetchPlantTotalGrid(filterData, pageNo, limit): Observable<any> {
    let queryString = this.getQuery(filterData);
    return this.http.get<any>(`${this.plantProfitCenter}grid/plantTotalOBD?page=${pageNo}&size=${limit}&${queryString}`)
      .pipe(catchError(this.handleError));
  }

  userLogout(): Observable<any> {
    return this.http.get(`${this.baseURL}/api/logout`).pipe(catchError(this.handleError));
  }

  ssoLogout(): Observable<any> {
    const logoutUrl = localStorage.get('logOutUrl')
    if(logoutUrl) {
      this.http.get(logoutUrl).pipe(catchError(this.handleError));
    }
    return null;
  }

  fetchUserToken(requestBody): Observable<any> {
    return this.http.post(`${this.baseURL}/api/login`, requestBody).pipe(
      catchError(this.handleError));
  }

  forgotPassword(sendData) {
    return this.http.post(this.baseURL + '/api/password-reset', sendData).pipe(
      catchError(this.handleError));
  }

  passwordReset(test) {
    return this.http.post(this.baseURL + '/api/change-password' + test, '').pipe(catchError(this.handleError));
  }

  private createAuthorizationHeader(authorizationToken): HttpHeaders {
    return new HttpHeaders(
      {
        'Authorization': `Bearer ${authorizationToken}`
      });
  }

  private handleError(errorRes: HttpErrorResponse) {
    return throwError(errorRes);
  }

  fetchUserCurrentDetails(authorizationToken): Observable<any> {
    const headers: HttpHeaders = this.createAuthorizationHeader(authorizationToken);
    return this.http.get(`${this.baseURL}/api/app-users`, { headers }).pipe(
      catchError(this.handleError)
    );
  }

  getCountryList(): Observable<any> {
    return this.http.get(this.baseURL + '/api/countries', {
      headers: this.createHeaderWithoutAuth()
    });
  }

  getStateList(data): Observable<any> {
    return this.http.get(this.baseURL + '/api/states/' + data, {
      headers: this.createHeaderWithoutAuth()
    })
  }

  getDistrictList(id): Observable<any> {
    return this.http.get(this.baseURL + '/api/districts/' + id, {
      headers: this.createHeaderWithoutAuth()
    })
  }
  createHeaderWithoutAuth() {
    return (this.header = new HttpHeaders({
      'Content-Type': 'application/json'
    }));
  }

  addNewAdmin(requestBody): Observable<any> {
    return this.http.post(`${this.baseURL}/api/farmetrix-app-users`, requestBody).pipe(
      catchError(this.handleError));
  }

  editAdmin(requestBody): Observable<any> {
    return this.http.put(`${this.baseURL}/api/farmetrix-app-users`, requestBody).pipe(
      catchError(this.handleError));
  }

  siteKey(): Observable<any> {
    return this.http.get<any>(`${this.baseURL}/api/site-key`, { responseType: 'text' as 'json' }).pipe(
      catchError(this.handleError)
    );
  }

  backendResponse(response: string): Observable<any> {
    return this.http.get<any>(`${this.baseURL}/api/site-key/${response}`,).pipe(
      catchError(this.handleError)
    );
  }

  activateDeactivateAdmin(commonType: string, id: number): Observable<any> {
    return this.http.put(`${this.baseURL}/api/farmetrix-app-users/${commonType}/${id}`, ``)
      .pipe(catchError(this.handleError));
  }

  //API integrated
  getTerritoryList(pageNo, limit, ugdn, name): Observable<any> {
    let filterUrl = '';
    if(ugdn) {
      filterUrl += `&id.in=${ugdn}`;
    }
    if(name) {
      filterUrl += `&id.in=${name}`;
    }
    return this.http.get<any>(`${this.baseURL}/api/app-users/all?sort=name&role.equals=TERRITORY_MANAGER&status.equals=true&page=${pageNo}&size=${limit}${filterUrl}`)
      .pipe(catchError(this.handleError));
  }

  getAllTerritoryList(): Observable<any> {
    return this.http.get<any>(`${this.baseURL}/api/app-users/list?sort=name&role.equals=TERRITORY_MANAGER&status.equals=true`)
      .pipe(catchError(this.handleError));
  }

  getAllDistributorCodeList(searchText): Observable<any> {
    return this.http.get<any>(`${this.baseURL}/api/distributors/all?sort=code&code.contains=${searchText}`)
      .pipe(catchError(this.handleError));
  }

  getAllDistributorNameList(searchText): Observable<any> {
    return this.http.get<any>(`${this.baseURL}/api/distributors/all?sort=name&name.contains=${searchText}`)
      .pipe(catchError(this.handleError));
  }

  exportTerritoryData(ugdn, name) {
    let filterUrl = '';
    if(ugdn) {
      filterUrl += `&id.in=${ugdn}`;
    }
    if(name) {
      filterUrl += `&id.in=${name}`;
    }
    return this.http.get<any>(`${this.baseURL}/api/app-users/export?sort=name&role.equals=TERRITORY_MANAGER&status.equals=true${filterUrl}`)
      .pipe(catchError(this.handleError));
  }

  exportDistributorData(code, name) {
    let filterUrl = '';
    if(code) {
      filterUrl += typeof code === 'object' ? `&orCode.contains=${code.code}` : `&orCode.contains=${code}`;
    }
    if(name) {
      filterUrl += typeof name === 'object' ? `&orName.contains=${name.name}` : `&orName.contains=${name}`;
    }
    return this.http.get<any>(`${this.baseURL}/api/distributors/export?sort=name${filterUrl}`)
      .pipe(catchError(this.handleError));
  }

  exportDistributorHistoryData(startDate, endDate) {
    let filterUrl = '';
    if(startDate) {
      filterUrl = `?fromDate=${startDate}`;
    }
    if(endDate) {
      filterUrl += startDate ? `&toDate=${endDate}` : `?toDate=${endDate}`;
    }
    return this.http.get<any>(`${this.baseURL}/api/products/scanned-products/export${filterUrl}`)
      .pipe(catchError(this.handleError));
  }

  exportDistributorProductData(code, startDate, endDate) {
    let filterUrl = '';
    if(startDate) {
      filterUrl = `&fromDate=${startDate}`;
    }
    if(endDate) {
      filterUrl += startDate ? `&toDate=${endDate}` : `&toDate=${endDate}`;
    }
    return this.http.get<any>(`${this.baseURL}/api/products/scanned-products/export?id.equals=${code}${filterUrl}`)
      .pipe(catchError(this.handleError));
  }

  getDistributorList(pageNo, limit, code, name): Observable<any> {
    let filterUrl = '';
    if(code) {
      filterUrl += typeof code === 'object' ? `&orCode.contains=${code.code}` : `&orCode.contains=${code}`;
    }
    if(name) {
      filterUrl += typeof name === 'object' ? `&orName.contains=${name.name}` : `&orName.contains=${name}`;
    }
    return this.http.get<any>(`${this.baseURL}/api/distributors?sort=name&page=${pageNo}&size=${limit}${filterUrl}`)
      .pipe(catchError(this.handleError));
  }

  getDailyUniqueProducts(startDate, endDate): Observable<any> {
    let filterUrl = '';
    if(startDate) {
      filterUrl = `&fromDate=${startDate}`;
    }
    if(endDate) {
      filterUrl += startDate ? `&toDate=${endDate}` : `&toDate=${endDate}`;
    }
    return this.http.get<any>(`${this.baseURL}/api/products/daily-unique-products?page=0&size=4${filterUrl}`)
      .pipe(catchError(this.handleError));
  }

  getDistributorDetails(startDate, endDate): Observable<any> {
    let filterUrl = '';
    if(startDate) {
      filterUrl = `?fromDate=${startDate}`;
    }
    if(endDate) {
      filterUrl += startDate ? `&toDate=${endDate}` : `?toDate=${endDate}`;
    }
    return this.http.get<any>(`${this.baseURL}/api/products/scanned-products${filterUrl}`)
      .pipe(catchError(this.handleError));
  }

  getDistributorProductDetails(userId, startDate, endDate): Observable<any> {
    let filterUrl = '';
    if(startDate) {
      filterUrl = `?fromDate=${startDate}`;
    }
    if(endDate) {
      filterUrl += startDate ? `&toDate=${endDate}` : `?toDate=${endDate}`;
    }
    return this.http.get<any>(`${this.baseURL}/api/products/scanned-products/${userId}${filterUrl}`)
      .pipe(catchError(this.handleError));
  }

  getAllGraphDetails(startDate, endDate): Observable<any> {
    let filterUrl = '';
    if(startDate) {
      filterUrl = `?fromDate=${startDate}`;
    }
    if(endDate) {
      filterUrl += startDate ? `&toDate=${endDate}` : `?toDate=${endDate}`;
    }
    return this.http.get<any>(`${this.baseURL}/api/products/graphs/dashboard-graphs${filterUrl}`)
      .pipe(catchError(this.handleError));
  }
}
