import browser from 'browser-detect';
import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';
import {AdminService} from '@app/services/admin.service';
import {environment as env} from '@env/environment';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router, RoutesRecognized} from '@angular/router';
import {EventsService} from '@app/events/events.service';
import {AuthService} from "@app/core/auth/auth.service";
import {routeAnimations} from './core/animations/route.animations';
import {LoadingSpinnerService} from "@app/shared/spinner/loading-spinner.service";
import {UserStateService} from "@app/core/auth/user-state.service";
import {delay, filter, pairwise, take, tap} from "rxjs/operators";
import {NotificationService} from "@app/core/notifications/notification.service";
import {onMainContentChange} from "@app/animations/animations";
import {SidenavService} from "@app/services/sidenav.service";
import * as L from "leaflet";
import * as _ from "lodash";
import {QueryParam, SearchWithPagination} from "@app/shared/util/request-util";
import {UserIdleService} from "angular-user-idle";
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';

export let browserRefresh = false;

@Component({
  selector: 'anms-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [onMainContentChange, routeAnimations],
})

export class AppComponent implements OnInit {
  version = env.versions.app;
  year = new Date().getFullYear();
  navigationSideMenu = [];
  isAuthenticated$: Observable<boolean>;
  role: String = '';
  path: any;
  isBgColor = false;
  isAdmin: boolean = false;
  isSuperAdmin: boolean = false;
  public onSideNavChange: boolean = false;

  currentLat: any = null;
  currentLong: any = null;
  defaultZoom: number = 17;
  map: L.Map;
  options = {
    zoomControl: true,
    zoom: this.defaultZoom,
    center: L.latLng(this.currentLat, this.currentLong),
  };
  isDashboard: boolean = false;
  displayGrowerFields$: Subject<boolean>;
  private readonly defaultAdminQueryParams: QueryParam[] = [
    {key: 'status.equals', value: true}
  ];
  editableLayers = new L.FeatureGroup();
  drawOptions = {
    position: 'topright',
    draw: {
      marker: false,
      polyline: false,
      rectangle: false,
      circlemarker: false,
      circle: false
    },
    edit: {
      featureGroup: this.editableLayers
    }
  };
  mapReady: boolean = false;
  appUserId: number;
  isShowLayers: boolean = false;
  isStreetLayer: boolean = false;
  isSatelliteLayer: boolean = false;
  breadcrumbSet: any = [];
  isFarmIntelAdmin: boolean = this.userStateService.isFarmIntelAdmin();
  isFarmIntelResourceManager: boolean = this.userStateService.isFarmIntelResourceManager();
  isFarmIntelContributor: boolean = this.userStateService.isFarmIntelContributor();
  isFarmIntelGrower: boolean = this.userStateService.isFarmIntelGrower();
  isGrower: boolean = this.userStateService.isGrower();
  currentLanguage: string;
  directionType: string;
  defaultLanguage: string = environment.defaultLanguage;
  subscription: Subscription;

  constructor(private router: Router, private events: EventsService,
    private route: ActivatedRoute, private cdRef: ChangeDetectorRef,
    private adminService: AdminService, private authService: AuthService,
    private loadingSpinnerService: LoadingSpinnerService,
    private userStateService: UserStateService,
    private notificationService: NotificationService,
    private _sidenavService: SidenavService,
    private userIdle: UserIdleService,
    private translate: TranslateService,
  ) {
    this.setPreviousUrlHistory();
    this._sidenavService.sideNavState$.subscribe( res => {
      this.onSideNavChange = res;
    });
    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
      }
    });
        translate.addLangs(['en', 'es', 'in', 'vi']);
        translate.use(this.defaultLanguage);
  }

  ngAfterContentChecked() {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    this.setBreadcrumbs();
    this.loadingSpinnerService.show();
    this.isAuthenticated$ = this.userStateService.isUserLoggedIn$;
    this.authService.autoLogin()
        .pipe(tap(autoLogin => {
          this.checkRoleAndRenderNavigationItems();
        }))
        .subscribe();
    this.subscribeToExternalEvents();
    this.currentLanguage = localStorage.getItem('currentLanguage');
    if (this.currentLanguage === null || this.currentLanguage === undefined) {
      this.currentLanguage = this.defaultLanguage;
    }
    if (this.currentLanguage === 'ur') {
      this.directionType = 'rtl';
    } else {
      this.directionType = 'ltr';
    }
  }

  setBreadcrumbs() : void {
    this.breadcrumbSet = [];
    this.events.breadcrumbs.emit(this.breadcrumbSet);
  }

  setPreviousUrlHistory() {
    const defaultUrl: string = '/dashboard';
    this.router.events
        .pipe(filter(event => event instanceof RoutesRecognized), pairwise())
        .subscribe((event: any[]) => {
          if (event && event.length && event[0] && event[0].urlAfterRedirects) {
            const previousUrl: string = event[0].urlAfterRedirects ? event[0].urlAfterRedirects : defaultUrl;
            localStorage.setItem('prvUrl', previousUrl);
          }
        });
  }

  checkRoleAndRenderNavigationItems() {
    this.userStateService.isUserLoggedIn$.pipe(
      delay(300),
      tap(isLoggedIn => {
      if (isLoggedIn) {
        this.isAdmin = this.userStateService.isAdmin();
        this.isSuperAdmin = this.userStateService.isSuperAdmin();
        this.isGrower = this.userStateService.isGrower();
        this.isFarmIntelAdmin = this.userStateService.isFarmIntelAdmin();
        this.isFarmIntelResourceManager = this.userStateService.isFarmIntelResourceManager();
        this.isFarmIntelContributor = this.userStateService.isFarmIntelContributor();
        this.isFarmIntelGrower = this.userStateService.isFarmIntelGrower();
        this.appUserId = this.userStateService.currentAppUserId();
        if (this.isFarmIntelAdmin || this.isFarmIntelResourceManager ||  this.isFarmIntelContributor ||  this.isFarmIntelGrower || this.isGrower) {
          this.currentLat = this.userStateService.currentLatitude();
          this.currentLong = this.userStateService.currentLongitude();
        }
        this.startWatchingForUserInactivity();
      } else {
        this.isAdmin = false;
        this.isSuperAdmin = false;
        this.stopWatching();
        this.isFarmIntelAdmin =  false;
        this.isFarmIntelResourceManager =  false;
        this.isFarmIntelContributor =  false;
        this.isFarmIntelGrower =  false;
        this.isGrower = false;
      }

       //Need to update after API integration
       if(isLoggedIn) {
          this.isFarmIntelAdmin = true;
          this.isShowLayers = true;
       }
     
    })).subscribe();
  }

  startWatchingForUserInactivity() {
    //Start watching for user inactivity.
    this.userIdle.startWatching();
    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(count => {});
    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      this.authService.logout();
    });
  }

  stopWatching() {
    this.userIdle.stopWatching();
  }

  centerMap(latitude, longitude) {
    this.map.setView(new L.LatLng(latitude, longitude), this.defaultZoom);
  }

  subscribeToExternalEvents() {
    this.events.setSideBarBgColor.subscribe(res => {
      this.isBgColor = false;
      if (res) {
        this.isBgColor = true;
      }
    });
    this.displayGrowerFields$ = this.events.displayGrowerField$;
  }

  inProgrees() {
    this.notificationService.warn(this.translate.instant(`Work in progress`));
  }

  onLogoutClick() {
    this.authService.logout();
  }

  onMapReady(map: L.Map) {
    this.map = map;
    this.mapReady = true;
    this.centerMap(this.currentLat, this.currentLong);
    L.tileLayer('https://{s}.google.com/vt/lyrs=s,h&hl=tr&x={x}&y={y}&z={z}', {
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
    }).addTo(map);
    this.isSatelliteLayer = true;
    // map.zoomControl.setPosition('bottomright');
    L.control.zoom({position: 'bottomright', zoomOutTitle: this.translate.instant('zoom out'), zoomInTitle: this.translate.instant('zoom in')}).addTo(map);
    this.map.attributionControl.addAttribution('Field data &copy; <a href="https://www.upl-ltd.com/" target=/"/_blank/">UPL Digital</a>');
  }


  addPolygon(latlngs, index, fieldDetails) {
    if (index === 0) {
      this.currentLat = latlngs[0][0];
      this.currentLong = latlngs[0][1];
      this.centerMap(this.currentLat, this.currentLong);
    }
    let polygon = L.polygon(latlngs,
        {color: 'rgb(245 255 7 / 60%)'}); // Creating a polygon

    polygon.on('click', (e) => {
      this.events.setFieldId.emit(fieldDetails.id);
      if(fieldDetails.registrationStatus || (fieldDetails.registrationStatus == null && fieldDetails.userCrops.length)){
        this.events.managePanels('false');
        localStorage.setItem('fieldId', JSON.stringify(fieldDetails.id));
        this.router.navigate(['/field-details']);
      } else {
        this.notificationService.error("Please add crop first");
        localStorage.setItem('fieldId', JSON.stringify(fieldDetails.id));
        this.router.navigate([`crop-details/add`]);
      }
    });

    this.editableLayers.addLayer(polygon);
    this.editableLayers.addTo(this.map);
  }

  addCircle(latlngs, radius, index, fieldDetails) {
    if (index === 0) {

    }
    let circle = L.circle(latlngs,
        {color: 'red', radius}); // Creating a polygon
    this.editableLayers.addLayer(circle);
    this.editableLayers.addTo(this.map);

    circle.on('click', (e) => {
      this.events.setFieldId.emit(fieldDetails.id);
      if(fieldDetails.registrationStatus || (fieldDetails.registrationStatus == null && fieldDetails.userCrops.length)){
        this.events.managePanels('false');
        localStorage.setItem('fieldId', JSON.stringify(fieldDetails.id));
        this.router.navigate(['/field-details']);
      } else {
        this.notificationService.error("Please add crop first");
        localStorage.setItem('fieldId', JSON.stringify(fieldDetails.id));
        this.router.navigate([`crop-details/add`]);
      }
    });
  }

  private static isIEorEdgeOrSafari() {
    return ['ie', 'edge', 'safari'].includes(browser().name);
  }

  toggleLayerPopup() {
    this.isShowLayers = !this.isShowLayers;
  }

  changeMapLayer(layer) {
    if (layer === 'street') {
      let streets = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {subdomains: ['mt0', 'mt1', 'mt2', 'mt3']});
      streets.addTo(this.map);
      this.isStreetLayer = true;
      this.isSatelliteLayer = false;
    } else {
      let grayScale = L.tileLayer('https://{s}.google.com/vt/lyrs=s,h&hl=tr&x={x}&y={y}&z={z}', {subdomains: ['mt0', 'mt1', 'mt2', 'mt3']});
      grayScale.addTo(this.map);
      this.isSatelliteLayer = true;
      this.isStreetLayer = false;
    }
  }
}
