import {Component, OnInit} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {LoadingSpinnerService} from "@app/shared/spinner/loading-spinner.service";

@Component({
  selector: 'spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})

export class SpinnerComponent implements OnInit{
  private toggleSpinnerSubject: Subject<boolean> = new Subject<boolean>();
  show$: Observable<boolean> = this.toggleSpinnerSubject.asObservable();
  constructor(private spinner: LoadingSpinnerService) {}
  ngOnInit(): void {
    this.show$ = this.spinner.showLoadingSpinner$;
  }
}
