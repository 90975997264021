import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {User} from './user.model';
import {AdminService} from "@app/services/admin.service";
import {LoadingSpinnerService} from "@app/shared/spinner/loading-spinner.service";
import {UserStateService} from "@app/core/auth/user-state.service";
import {Observable} from "rxjs";
import {NotificationService} from '../notifications/notification.service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@env/environment';

@Injectable()
export class AuthService {

    defaultLanguage: string = environment.defaultLanguage;

    constructor(private http: HttpClient,
                private router: Router,
                private adminService: AdminService,
                private loadingSpinnerService: LoadingSpinnerService,
                private userStateService: UserStateService,
                private notificationService: NotificationService,
                private translate: TranslateService)
              {}

    login(email: string, password: string, reCaptchaToken: string): void {
        this.loadingSpinnerService.show();
        const userLoginPayload: any = {
            username: email,
            password: password,
            gRecaptcha: reCaptchaToken
        };
        this.fetchTheUserToken(userLoginPayload);
    }

    fetchTheUserToken(userLoginPayload): void {
        this.adminService.fetchUserToken(userLoginPayload)
            .subscribe((successResponse) => this.fetchUserTokenSuccess(successResponse),
                (error) => {
                    this.loadingSpinnerService.hide();
                    this.notificationService.error(error.error.detail);
                });
    }

    fetchUserTokenSuccess(successResponse): void {
        if (successResponse) {
            const userToken: string = successResponse.token;
            localStorage.setItem('currentToken', userToken);
            this.fetchAuthenticatedUserDetails(userToken);
            this.notificationService.success(this.translate.instant('You are successfully logged in'));
        }
    }

    fetchAuthenticatedUserDetails(authorizationToken): void {
        this.adminService.fetchUserCurrentDetails(authorizationToken)
            .subscribe((res) => {
                res['token'] = authorizationToken;
                this.handleAuthentication(res);
            }, (err) => {
                this.loadingSpinnerService.hide();
            });
    }

    handOverUserAuth(res): void {
        let adminToken = this.userStateService.fetchSpecificCurrentUserDetails('token');
        localStorage.setItem("adminToken", adminToken);
        this.handleAuthentication(res);
    }

    handoverBackToAdmin(userToken): void {
        this.logout(userToken);
    }

    /**
     * Fetch user details from local storage
     * If user data is present in the local storage then auto login the user
     * If the user data is not present in the local storage does't do anything
     */
    autoLogin(): Observable<boolean> {
        return new Observable((observer) => {

            try {
                const userData: {
                    email: string;
                    id: string;
                    token: string;
                    role: string;
                    city: string;
                    countryId: number;
                    countryName: string;
                    districtId: number;
                    firstName: string;
                    lastName: string;
                    latitude: number;
                    longitude: number;
                    mobileNumber: string;
                    mobileNumberCountryId: number;
                    parentId: number;
                    pincode: number;
                    profilePhotoPath: string;
                    stateId: number;
                    status: boolean;
                    street: string;
                } = JSON.parse(localStorage.getItem('userData'));
                this.afterLoginToken(userData);
                if (!userData) {
                    observer.next(true);
                    observer.complete();
                    return;
                }
                const loadedUser = this.returnUserObject(userData);
                if (loadedUser.token) {
                    this.userStateService.notifyAll(loadedUser);
                }
                observer.next(true);
                observer.complete();
            } catch (e) {
                observer.next(false);
                observer.complete();
            }
        });
    }

    afterLoginToken(res): void {
        localStorage.setItem('currentToken', res.token)
    }

    logout(authToken?): void {
        this.loadingSpinnerService.show();        
        this.adminService.userLogout().subscribe(
            data =>  this.logOutSuccess(data, authToken),
            error => this.logOutError(error, authToken)
        );
    }

    logOutSuccess(data, authToken?): void {
        // this.clearCurrentUserState(authToken);
        this.loadingSpinnerService.hide();
        this.userStateService.notifyAll(null);
        this.router.navigate(['/sign-in']);
        this.notificationService.success(this.translate.instant(data.message));
        //OKTA logout
        // this.adminService.ssoLogout().subscribe(
        //     data =>  {},
        //     error => {}
        // );
    }

    logOutError(error, authToken): void {
        this.clearCurrentUserState(authToken);
        this.loadingSpinnerService.hide();
        this.notificationService.error(this.translate.instant(error));
    }

    clearCurrentUserState(authToken?): void {
        this.userStateService.notifyAll(null);
        localStorage.removeItem("currentToken");
        localStorage.removeItem("userData");
        localStorage.removeItem("adminToken");
        localStorage.removeItem("prvUrl");
        localStorage.removeItem("fieldId");
        localStorage.removeItem("weed_Id");
        localStorage.removeItem("editFieldId");
        localStorage.removeItem("userCropID");
        localStorage.removeItem("adminEditCropFieldId");
        localStorage.removeItem("adminUserId");
        localStorage.removeItem("leftMenuClick");
        localStorage.removeItem("editUserId");
        localStorage.removeItem("currentWeedId");
        localStorage.removeItem("queryParams");
        localStorage.removeItem("currentGrowerId");
        localStorage.removeItem("currentCropId");
        localStorage.removeItem("currentCropValue");
        localStorage.removeItem('currentGrowerRole');
        if (authToken) {
            this.fetchAuthenticatedUserDetails(authToken);
        } else {
            this.router.navigate(['/sso/sign-out']);
        }
    }

    private handleAuthentication(respData): void {
        const user = this.returnUserObject(respData);
        this.userStateService.notifyAll(user);
        localStorage.setItem('userData', JSON.stringify(user));
        localStorage.setItem('profileData', JSON.stringify(user));
        
        if (this.router.url.includes('dashboard')) {
            window.location.reload();
        } else {
            this.router.navigate(['/dashboard']);
        }
    }

    private returnUserObject(respData) {
        return new User(respData.email, respData.id,
            respData.token, respData.role,
            respData.city, respData.countryId, respData.countryName,
            respData.districtId, respData.firstName,
            respData.lastName, respData.latitude,
            respData.longitude, respData.mobileNumber,
            respData.mobileNumberCountryId, respData.parentId,
            respData.pincode, respData.profilePhotoPath,
            respData.stateId, respData.status,
            respData.street);
    }
}
