import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared';
import {AppHeaderComponent} from '@app/shared/app-header/app-header.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from '@app/app-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import {ClickOutsideModule} from 'ng-click-outside';

@NgModule({
    declarations: [AppHeaderComponent],
    imports: [
        CommonModule,
        SharedModule,
        BrowserAnimationsModule,
        BrowserModule,
        AppRoutingModule,
        TranslateModule,
        ClickOutsideModule
    ],
    exports: [AppHeaderComponent]
})
export class AppHeaderModule {}
